import React, { Component } from "react"
import { StaticImage } from "gatsby-plugin-image"

const VideoPage = () => (
  <div>
    <video controls width="100%" height="auto">
      <source src="/videos/moving_blob.mp4" type="video/mp4" />
      あなたのブラウザは動画タグをサポートしていません。
    </video>
  </div>
)

export default class App extends Component {
  render() {
    return <VideoPage />
  }
}
